<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    fill="none"
    viewBox="0 0 12 21"
  ><path
    fill="currentColor"
    fill-opacity=".59"
    d="M4.94 17.25c-.54-2.13.63-3.85 1.45-4.73-.93-.93-1.95-1.88-2.89-2.8-1.65 1.93-2.79 4.2-2.18 6.56 1.57 6.06 7.34 3.81 7.34 3.81s-2.92.3-3.72-2.84Z"
  /><path
    fill="#FEC67C"
    d="M10.75 10.41a13.5 13.5 0 0 0-5.01-6.97A4.96 4.96 0 0 0 2.8 2.42C1.17 2.47-.58 3.75.18 6.27c.68 2.25 6.54 5.9 8.57 9 2.04 3.1-.1 4.83-.1 4.83s2.57-1.5 2.63-3.94c.2-2.02.02-3.95-.53-5.75ZM4.53 5.92c-1.38-2.17.15-3.1 1.1-2.47.5.35 1.8 1.33 3 2.98a13.43 13.43 0 0 1 2.62 9.16c-.03-.26-.12-.53-.2-.82-1.1-3.81-4.94-6.36-6.52-8.85Z"
  /><path
    fill="#FEC67C"
    fill-opacity=".59"
    d="M11.2 7.23s-.87-6.1-3.44-6.68c-1.02-.23-1.7.4-1.64 1.12.1 1 1.16 1.2 1.86 1.02 1.35-.33 3.22 4.54 3.22 4.54Z"
  /></svg>
</template>
